import React from 'react'
import { graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import PageTemplate from '../components/PageTemplate'

// query to load agency data from excel file
// NOTE: graphql queries must exist in a 'page' file...they will not work within a component file
export const IndexQuery = graphql`
    query {
        allAgencydataXlsxSheet1 {
            edges {
                node {
                    name
                    categories
                    disciplines
                    locations
                    headline
                    link
                    logo
                }
            }
        }
        allAgencydataXlsxSheet2 {
            edges {
                node {
                    categories
                }
            }
        }
    }
`

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #001b33;
  }
`

const PageContent = styled.div`
    -webkit-font-smoothing: subpixel-antialiased;
    position: relative;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-top: 135px;
        padding-bottom: 1px;
        z-index: 1;
    }
`
const PageContentInner = styled.div`
    padding-left: ${(props) => props.theme.paddingSidesMobile};
    padding-right: ${(props) => props.theme.paddingSidesMobile};
    padding-top: 100px;
    padding-bottom: 40px;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-left: ${(props) => props.theme.paddingSidesDesktop};
        padding-right: ${(props) => props.theme.paddingSidesDesktop};
        padding-top: 0;
        padding-bottom: 0;
    }
`
const CopyContainer = styled.div`
    width: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    // margin-bottom: 120px;

    h1 {
        color: ${(props) => props.theme.blueDark};
        font-weight: 900;
        font-size: 2.1875rem;
    }

    p {
        color: ${(props) => props.theme.blueDark};
        line-height: 1.75;
        margin-bottom: 0;
    }

    h5 {
        margin-top: 0;
        width: 60%;
        color: ${(props) => props.theme.blueDark};
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        width: 100%;
        // margin-bottom: 45px;

        p {
            font-size: 1.1rem;
        }

        h5 {
            margin-top: 0;
            width: auto;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointDesktop}) {
        h1 {
            font-size: 2.875rem;
        }
    }
`

const BackgroundContent = styled.div`
    background: linear-gradient(to right, #cddbe7, #fff);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-bottom-width: 2px;
    border-bottom-color: white;
    border-bottom-style: solid;
    -webkit-box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);
    box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
    }
`
const AgencyCards = styled.div`
    margin-top: 3rem;
    margin-bottom: 0;
    display: none;

    &.show {
        display: block;
    }

    ul {
        display: inline-block;
        vertical-align: top;
        width: 100%;

        li {
            width: 100%;
        }
    }

    button {
        margin: 1rem 1rem 0 0;
        cursor: pointer;
        max-height: 28px;
        width: 28px;
        height: 28px;
        border: none;
        float: right;
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        margin-bottom: 30px;

        button {
            font-size: 3rem;
        }
    }
`

const ResultContainer = styled.div`
    display: inline-grid;
    width: 100%;
    position: relative;
    order: 0;
    flex: 1;
    align-self: auto;
    flex: 0 1 auto;
    height: 310px;
    perspective: 1000px;

    @media all and (-ms-high-contrast: none) {
        display: -ms-inline-flexbox;
    }

    &:last-child .flip-card-front,
    &:last-child .flip-card-back {
        border-bottom: 2px solid #79bde8;
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        width: 25%;
        max-height: 12rem;

        &:nth-child(-n + 4) .flip-card-front,
        &:nth-child(-n + 4) .flip-card-back {
            border-top: 2px solid #79bde8;
        }

        &:nth-child(4n) .flip-card-front,
        &:nth-child(4n) .flip-card-back {
            border-right: 2px solid #79bde8;
        }

        &:nth-child(4n + 1) .flip-card-front,
        &:nth-child(4n + 1) .flip-card-back {
            border-left: 2px solid #79bde8;
        }

        &:nth-last-child(2) .flip-card-front,
        &:nth-last-child(2) .flip-card-back,
        &:nth-last-child(3) .flip-card-front,
        &:nth-last-child(3) .flip-card-back,
        &:nth-last-child(4) .flip-card-front,
        &:nth-last-child(4) .flip-card-back,
        &:last-child .flip-card-front,
        &:last-child .flip-card-back {
            border-bottom: 2px solid #79bde8;
        }

        //uncomment below when video is added

        // &:nth-child(4) .flip-card-front,
        // &:nth-child(7) .flip-card-front,
        // &:nth-child(4) .flip-card-back,
        // &:nth-child(7) .flip-card-back,
        // &:nth-child(4n+11) .flip-card-front{
        //  border-right: 2px solid #79bde8;
        // }
        //
        //
        //  &:nth-child(-n+2) .flip-card-front,
        //  &:nth-child(-n+2) .flip-card-back,
        //  &:nth-last-child(2) .flip-card-front,
        //  &:nth-last-child(2) .flip-card-back,
        //  &:nth-last-child(3) .flip-card-front,
        //  &:nth-last-child(3) .flip-card-back,
        //  &:nth-last-child(4) .flip-card-front,
        //  &:nth-last-child(4) .flip-card-back {
        //   border-bottom: 2px solid #79bde8;
        //  }
        //
        //  &:nth-child(6) .flip-card-front,
        //  &:nth-child(6) .flip-card-back,
        //  &:nth-child(4n+8) .flip-card-front,
        //  &:nth-child(4n+8) .flip-card-back {
        //   border-left: 2px solid #79bde8;
        //  }
        //
        // &:nth-child(-n+4) .flip-card-front,
        // &:nth-child(-n+4) .flip-card-back,
        // &:nth-child(8) .flip-card-front,
        // &:nth-child(8) .flip-card-back,
        // &:nth-child(9) .flip-card-front,
        // &:nth-child(9) .flip-card-back {
        //   border-top: 2px solid #79bde8;
        // }
        //
        // &:last-child .flip-card-front,
        // &:last-child .flip-card-back {
        //   border: 2px solid #79bde8;
        //   border-top: 1px solid #79bde8;
        // }
    }

    @media (min-width: ${(props) => props.theme.breakpointLargeDesktop}) {
        max-height: 20rem;
    }
`

const ImageHelper = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    position: relative;
`

// const AgencyLogo = styled.img`
//     display: block;
//     max-height: 100%;
//     max-width: 90%;
//     width: auto;
//     height: auto;
//     position: absolute;
//     margin: auto;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     top: -15%;
// `

const AgencyLogo = styled.img`
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`

const VisitButton = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.875rem;
    color: ${(props) => props.theme.blueDark};
    background-color: ${(props) => props.theme.blueLight};
    position: relative;
    bottom: 5.429rem;
    height: 1.875rem;
    margin: 1rem auto;
    width: 80%;
    border-radius: 5px;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        margin-top: 2.5rem;
        font-size: 0.6rem;
    }

    @media (min-width: ${(props) => props.theme.breakpointLargeDesktop}) {
        margin-top: 1rem;
        font-size: 1rem;
    }
`

const FlipCardInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    &.active {
        & .flip-card-front {
            pointer-events: none;
            opacity: 0;
            z-index: -1;
        }

        & .flip-card-back {
            opacity: 1;
            z-index: 1;
        }
    }
`

const FlipCardFront = styled.div`
    width: 100%;
    height: 100%;
    text-align: left;
    border: 2px solid #79bde8;
    border-bottom: none;
    background-color: white;
    z-index: 1;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    opacity: 1;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        border: 1px solid #79bde8;
    }
`

class IndexPage extends React.Component {
    state = {
        loadedAgencyData: [],
        completeMenusLists: {
            categories: [],
            disciplines: [],
            locations: [],
        },
        resultsModulesJSx: [],
        buttonsLabelsList: [],
        resultsViewCallback: this.props.resultsViewCallback,
        startoverCallback: this.props.startoverCallback,
        showingResults: false,
        selectedCategory: 'DTC Marketing',
        showingDisciplines: false,
        selected: {},
    }

    componentDidMount = () => {
        // load agency data from excel file and store in a variable
        let excelAgencyData = this.props.data.allAgencydataXlsxSheet1.edges.map((agency) => {
            // store agency data into a new object
            let dataObj = { ...agency.node }
            // convert certain agency data from string lists to arrays
            dataObj.categories = dataObj.categories.split(';')
            dataObj.disciplines = dataObj.disciplines.split(';')
            dataObj.locations = dataObj.locations.split(';')
            return dataObj
        })

        // get list of categories from second sheet of excel file
        // NOTE: this page is where the order of the buttons on this page is determined
        let excelAgencyCategories = []
        for (let i = 0; i < this.props.data.allAgencydataXlsxSheet2.edges.length; i++) {
            if (this.props.data.allAgencydataXlsxSheet2.edges[i].node.categories)
                excelAgencyCategories.push(this.props.data.allAgencydataXlsxSheet2.edges[i].node.categories)
        }
        // add the list of categories for the buttons to the state
        this.setState({ buttonsLabelsList: [...excelAgencyCategories] })

        // process agency data and store into component state by setting up a promise
        // to resolve once process is complete
        new Promise((resolve, reject) => {
            this.setState(
                (state) => {
                    state.loadedAgencyData = excelAgencyData
                },
                () => {
                    // console.log(this.state.loadedAgencyData)

                    for (let i = 0; i < this.state.loadedAgencyData.length; i++) {
                        console.log(this.state.loadedAgencyData[i].disciplines)
                    }
                }
            )
        })
            .then(() => {
                // add the first menu upon parsing off all data
                //this.buildCategoryButtons()
            })
            .catch((error) => {
                console.log(`Error processing excel data: ${error}`)
            })
    }

    selectItem = (agency) => {
        var selected = this.state.selected
        selected[agency] = !selected[agency]
        this.setState({ selected: selected })
    }

    outputDisciplines(agency) {
        agency.disciplines.map((discipline) => {
            if (discipline !== 'NULL') {
                return <li key={discipline}>{discipline}</li>
            }
        })
    }

    render() {
        return (
            <PageTemplate metaDataIndex={3} selectedNavIndex={3}>
                <GlobalStyles />
                <PageContent>
                    <BackgroundContent></BackgroundContent>
                    <PageContentInner role="main" id="main">
                        <CopyContainer>
                            <h1>The Difference Is an Unparalleled Network</h1>
                            <p>
                                As a global network of communications, marketing, and advertising agencies, we specialize in all
                                areas of healthcare. We offer clients our depth and breadth of experience and talent in every
                                market. We deliver ideas that change behaviors and improve the lives of patients and healthcare
                                professionals across the world. We exist to make a real difference for our clients and their
                                brands.
                            </p>
                        </CopyContainer>

                        <AgencyCards className={!this.state.showingResults && 'show'}>
                            {this.state.loadedAgencyData.map((agency, index) => {
                                console.log('🚀 ~ IndexPage ~ {this.state.loadedAgencyData.map ~ agency:', agency)
                                return (
                                    <React.Fragment key={index}>
                                        <ResultContainer key={agency.name} data-index={index} className="result-container">
                                            <FlipCardInner
                                                className={`flip-card-inner ${
                                                    this.state.selected[agency.name] ? 'active' : 'inactive'
                                                }`}
                                            >
                                                <FlipCardFront className="flip-card-front">
                                                    <ImageHelper>
                                                        <a
                                                            className={'agency-link'}
                                                            href={agency.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <AgencyLogo
                                                                src={`/images/${agency.logo}`}
                                                                alt={`${agency.name} logo`}
                                                            />
                                                        </a>
                                                    </ImageHelper>
                                                    <a
                                                        className={'visit-link'}
                                                        href={agency.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <VisitButton>
                                                            {agency.name === 'Rise & Run' ? 'COMING SOON' : 'VISIT THIS AGENCY'}
                                                        </VisitButton>
                                                    </a>
                                                </FlipCardFront>
                                            </FlipCardInner>
                                        </ResultContainer>
                                    </React.Fragment>
                                )
                            })}
                        </AgencyCards>
                    </PageContentInner>
                </PageContent>
            </PageTemplate>
        )
    }
}

export default IndexPage
